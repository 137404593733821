const dataList = [
  {
    id: 1,
    label: 'Rings',
    link: '/rings',
  },
  {
    id: 2,
    label: 'Categorias',
    submenu: [
      {
        id: 1,
        label: 'Cars',
        link: '/cars',
      },
      {
        id: 2,
        label: 'Music',
        link: '/music',
      },
      {
        id: 3,
        label: 'People',
        link: '/people',
      },
    ],
  },
];

export default dataList;
